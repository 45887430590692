import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import {
	FeaturedClearing,
	FeaturedPostgraduate,
	FeaturedUndergraduate,
} from '@uc/web/shared/data-models';
import { PrimaryBlueBtnComponent, VisitWebsiteBtnComponent } from '@web/shared/ui';
import { ScrollIntoViewDirective } from '../directives/scroll-into-view.directive';

@Component({
	selector: 'uc-client-profile-sticky-lead-bar-mobile',
	standalone: true,
	templateUrl: './client-profile-sticky-lead-bar-mobile.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [VisitWebsiteBtnComponent, PrimaryBlueBtnComponent, ScrollIntoViewDirective],
})
export class ClientProfileStickyLeadBarMobileComponent {
	isFeatured = input.required<
		FeaturedUndergraduate | FeaturedPostgraduate | FeaturedClearing
	>();
}
